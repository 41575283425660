@font-face {
  font-family: "Almarai";
  src: url("./fonts/Almarai-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LamaSans";
  src: url("./fonts/LamaSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.ttf") format("truetype");
}
